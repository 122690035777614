import { Injectable } from "@angular/core";
import {
  UserManager,
  UserManagerSettings,
  User,
  Profile,
  WebStorageStateStore,
} from "oidc-client";
import { ConfigurationService, IConfiguration } from "../config/config.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { PorscheTools } from "app/common/PorscheToolsStore";
import { takeUntil } from "rxjs/operators";

export enum AuthType {
  EntraID = "entraId",
  GroupIDP = "groupIdp",
}
// Service to do the user authentification
@Injectable({
  providedIn: "root",
})
export class AuthService {
  // Manager
  private manager: UserManager;

  // User
  private user: User = null;

  private serverUrl: string;

  // Auth change event
  private _changed: BehaviorSubject<void> = new BehaviorSubject<void>(null);

  authType: AuthType;
  config: ConfigurationService;

  get changed(): Observable<void> {
    return this._changed.asObservable();
  }

  // Get auth header
  get AuthorizationHeader(): string {
    if (this.user == null) {
      return null;
    }
    return this.user.token_type + " " + this.user.access_token;
  }

  constructor(config?: ConfigurationService) {
    if (config) {
      this.config = config;
    }

    if (this.config) {
      this.InitAuth();
    }
  }

  InitAuth() {
    const authType = sessionStorage.getItem("authType");
    if (authType == "entraId") {
      this.authType = AuthType.EntraID;
    }
    if (authType == "groupIdp") {
      this.authType = AuthType.GroupIDP;
    }
    if (!this.config || !this.authType) {
      return;
    }

    if (this.authType == AuthType.EntraID) {
      const authData = this.config?.auth_EntraId;
      if (PorscheTools.isNullOrUndefined(authData)) {
        return;
      }

      if (this.manager) {
        return;
      }

      this.serverUrl = this.config.serverUrl;
      let defaultUri = this.serverUrl;

      let loginUri = defaultUri;
      let logoutUri = defaultUri;
      let silentUri = defaultUri;

      if (!PorscheTools.stringIsNullOrEmpty(authData.redirect_uri)) {
        loginUri = authData.redirect_uri;
      }

      loginUri = loginUri + "/auth-callback";
      logoutUri = logoutUri + "/logout-callback";
      silentUri = silentUri + "/silent-callback";

      // https://materialmaster-dev.porsche.com/auth-callback
      var userStore = new WebStorageStateStore({ store: localStorage });

      // Create manager (must match client settings in auth server)
      this.manager = new UserManager({
        authority: authData.authUrl,
        client_id: authData.clientId,
        //redirect_uri: config.serverUrl + '/auth-callback',
        redirect_uri: loginUri,
        //silent_redirect_uri: silentUri,
        //post_logout_redirect_uri: logoutUri,
        response_type: "id_token token",
        scope: authData.scope,
        extraQueryParams: {
          resource: authData.identifier,
        },
        loadUserInfo: false,
        userStore: userStore,
      } as UserManagerSettings);

      // Set the user
      this.manager.getUser().then((user) => {
        this.user = user;
        this._changed.next(null);
      });
    }
    if (this.authType == AuthType.GroupIDP) {
      const authData = this.config?.auth_groupidp;
      if (PorscheTools.isNullOrUndefined(authData)) {
        return;
      }

      if (this.manager) {
        return;
      }

      this.serverUrl = this.config.serverUrl;
      let defaultUri = this.serverUrl;

      let loginUri = defaultUri;
      let logoutUri = defaultUri;
      let silentUri = defaultUri;

      if (!PorscheTools.stringIsNullOrEmpty(authData.redirect_uri)) {
        loginUri = authData.redirect_uri;
      }

      loginUri = loginUri + "/auth-callback";
      logoutUri = logoutUri + "/login";
      silentUri = silentUri + "/silent-callback";

      // https://materialmaster-dev.porsche.com/auth-callback
      var userStore = new WebStorageStateStore({ store: localStorage });

      // Create manager (must match client settings in auth server)
      this.manager = new UserManager({
        authority: authData.authUrl,
        client_id: authData.clientId,
        // redirect_uri: config.serverUrl + '/auth-callback',
        redirect_uri: loginUri,
        silent_redirect_uri: silentUri,
        post_logout_redirect_uri: logoutUri,
        response_type: "id_token token",
        scope: authData.scope,
        
      } as UserManagerSettings);

      // Set the user
      this.manager.getUser().then((user) => {
        this.user = user;
        this._changed.next(null);
      });
    }
  }

  // Login
  Login() {
    sessionStorage.setItem("requestedUrl", "/");
    return this.manager.signinRedirect();
  }

  // Logout
  async LogoutAsync() {
    this._changed.next(null);
    sessionStorage.removeItem("authType");
    // if (this.user && this.user.id_token) {
    //   const idTokenHint = this.user.id_token;
    //   await this.manager.signoutRedirect({
    //     id_token_hint: idTokenHint,
    //   });
    // }

    await this.manager.signoutRedirect();
  }

  // Complete the authentification loop
  async CompleteAuthenticationAsync(): Promise<string> {
    this.user = await this.manager.signinRedirectCallback();
    this._changed.next(null);
    const requestedUrl = sessionStorage.getItem("requestedUrl");

    return requestedUrl;
  }

  async CompleteLogoutAsync() {
    this.user = null;
    const response = await this.manager.signoutRedirectCallback();
    const requestedUrl = this.serverUrl;
    return requestedUrl;
  }

  async SilentSignInAuthenticationAsync() {
    return await this.manager.signinSilentCallback();
  }

  // Check if the user is authenticated
  IsAuthenticated(): boolean {
    return this.user != null && !this.user.expired;
  }

  // Get the userid
  GetUserId(): string | null {
    return this.IsAuthenticated() ? this.user.profile.sub : null;
  }

  // Get user profile
  GetUserProfile(): Profile | null {
    return this.IsAuthenticated() ? this.user.profile : null;
  }

  GetAccessToken() {
    return this.user ? this.user.access_token : null;
  }
}
