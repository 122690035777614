<mat-drawer-container autosize class="mainContainerMaterialOverview">
    <!-- <mat-drawer-container autosize> -->
    <mat-drawer #sidenav class="sidenav" mode="over" position="end">
        <p-grid>
            <p-grid-item size="12" class="filter-item">
                <p-headline variant="headline-4">{{'common.filter-menu' | translate}}</p-headline>
            </p-grid-item>
            <p-grid-item size="12" class="filter-item">
                <p-button variant="primary" class="filter-button" icon="filter" (click)="onFilter()">
                    {{'common.filter-apply' | translate}}</p-button>
                <p-button variant="secondary" class="filter-button" icon="delete" (click)="onFilterDelete()">
                    {{'common.filter-delete' | translate}}</p-button>
            </p-grid-item>
            <form [formGroup]="formFilter">
                <ng-container
                    *ngFor="let materialDefinition of materialDefinitionService.sortedMaterialDefinitionsDetail; index as i">
                    <p-grid-item size="12" class="filter-item">
                        <p-divider></p-divider>
                    </p-grid-item>
                    <p-grid-item size="12" class="filter-item">
                        <p-headline variant="headline-5">{{'grouping.material.' + i | translate}}</p-headline>
                    </p-grid-item>

                    <ng-container *ngFor="let attributeRowDefinition of materialDefinition">
                        <p-grid-item *ngFor="let attributeDefinition of attributeRowDefinition" size="12"
                            class="filter-item">

                            <p-text-field-wrapper *ngIf="attributeDefinition.displayType == DisplayType.Input"
                                [label]="'material.attribute.' + attributeDefinition.technicalName | translate">
                                <input [type]="'text'" [(ngModel)]="filter[attributeDefinition.technicalName]"
                                    [formControlName]="attributeDefinition.technicalName" />
                            </p-text-field-wrapper>

                            <!-- Select -->
                            <p-select-wrapper *ngIf="attributeDefinition.displayType == DisplayType.Select"
                                [label]="(('material.attribute.' + attributeDefinition.technicalName) | translate)">

                                <select (change)="onSelectChanged(attributeDefinition, $event.target)"
                                    [formControlName]="attributeDefinition.technicalName"
                                    [(ngModel)]="filter[attributeDefinition.technicalName]">

                                    <option [ngValue]="null">{{'common.select' | translate}}</option>
                                    <option *ngFor="let option of getSelectOptions(attributeDefinition)"
                                        [ngValue]="option">
                                        {{'material.selection.' + attributeDefinition.technicalName + '.' + option |
                                        translate}}
                                    </option>
                                </select>
                            </p-select-wrapper>
                        </p-grid-item>
                    </ng-container>
                </ng-container>
            </form>
        </p-grid>
    </mat-drawer>

    <div id="root">
        <p-flex class="portal_portal__lyj-w hydrated">
            <p-flex-item class="content_content__n2p-z hydrated">
                <button pButton type="button" icon="pi pi-user" class="content_feedbackBtn__h7LT7" (click)="openURL()" >
                    Feedback
                </button>
            </p-flex-item>

            <div id="content" class="mainContainerOverviewBody">
                <div class="mainContainerOverviewHeader">
                    <div class="mainContainerOverviewSearch">
                        <form [formGroup]="searchForm" (ngSubmit)="search()">
                            <p-text-field-wrapper [label]="'common.search-placeholder' | translate">
                                <input [type]="'search'" formControlName="search" />
                            </p-text-field-wrapper>
                        </form>
                    </div>
                    <p-button (click)="sidenav.toggle()" variant="tertiary" icon="filter">{{'common.filter-menu' | translate}}
                    </p-button>
                </div>
        
                <div id="table-container" class="mainContainerOverviewTableContainer">
                    <mat-table [dataSource]="data" matSort matSortActive="lastModificationDate" matSortDirection="desc"
                        matSortDisableClear>
                        <!-- Actions -->
                        <ng-container matColumnDef="actions">
                            <mat-header-cell *matHeaderCellDef>{{'common.actions' | translate}}</mat-header-cell>
                            <mat-cell *matCellDef="let material">
                                <p-button variant="tertiary" hide-label="true" icon="view"
                                    [routerLink]="['/inspect', material.id, material.revision]"></p-button>
                                <p-button *ngIf="user.currentUser?.authorization?.workflowStepAuth[0]" class="edit-button"
                                    variant="tertiary" hide-label="true" icon="edit"
                                    [routerLink]="['/workflow/clone', material.id, material.revision]">
                                </p-button>
                                <p-button variant="tertiary" class="edit-button" hide-label="true"
                                    iconSource="assets/img/copy-icon.svg" (click)="copyToClipboardAsync(material)"
                                    [matTooltip]="material.id + ' copied!'" #myTooltip="matTooltip" matTooltipPosition="right"
                                    [matTooltipDisabled]="true">
                                </p-button>
                                <!-- <p-button *ngIf="user.currentUser?.authorization?.workflowStepAuth[0]"
                                    [matMenuTriggerFor]="userWorkflowMenu" class="edit-button" variant="tertiary"
                                    hide-label="true" icon="list" (click)="GetWorkflowByMaterial(material)">
                                </p-button>
                                <mat-menu #userWorkflowMenu="matMenu">
                                    <div *ngFor="let output of workflowUsers" id="output">{{GetHeader(output) | translate}}:
                                        {{GetValue(output) | translate}}</div>
                                </mat-menu> -->
                            </mat-cell>
                        </ng-container>
        
                        <!-- Columns -->
                        <ng-container *ngFor="let col of displayedColumns">
                            <ng-container *ngIf="col != 'actions'" [matColumnDef]="col">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                    <!-- {{'material.attribute.' + col| translate}} -->
                                    <div>{{formatService.formatTableHeader(material[col], col) | translate}}</div>
                                </mat-header-cell>
                                <mat-cell *matCellDef="let material">
                                    {{formatService.toReadView(material[col], col)}}
                                </mat-cell>
                            </ng-container>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
                </div>
                <div class="mainContainerOverviewFooter">
                    <div class="pagination">
                        <p-pagination [totalItemsCount]="currentItemsCount" [itemsPerPage]="itemsPerPage"
                            [activePage]="activePage" (pageChange)="paging($event)"></p-pagination>
                    </div>
                    <div class="mainContainerOverviewSelect">
                        <mat-form-field appearance="fill">
                            <mat-label>{{'common.itemsPerPage' | translate}}</mat-label>
                            <mat-select [(ngModel)]="itemsPerPage" (selectionChange)="doPaging()">
                                <mat-option [value]="10">10</mat-option>
                                <mat-option [value]="25">25</mat-option>
                                <mat-option [value]="50">50</mat-option>
                                <mat-option [value]="100">100</mat-option>
                                <mat-option [value]="250">250</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="totalCount">{{'common.materialsPerPage' | translate}}: {{currentItemsCount}}</div>
                </div>
            </div>
        </p-flex>
    </div>
    
</mat-drawer-container>