import { Component, ViewChild, OnInit, AfterViewInit, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { MaterialService } from 'app/services/material.service';
import { MatSort } from '@angular/material/sort';
import { Material } from 'app/model/material';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FormatService } from 'app/services/format.service';
import { MatSidenav } from '@angular/material/sidenav';
import { MaterialDefinitionService } from 'app/services/materialDefinition.service';
import { UserService } from 'app/services/auth/user.service';
import { PorscheTools } from '../../common/PorscheToolsStore';
import { AttributeDefinition, DisplayType } from '../../model/materialDefinition';
import { KeyValueDict } from '../../common/KeyValueDict';
import { WorkflowService } from 'app/services/workflow.service';
import { Workflow } from 'app/model/workflow';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from 'app/services/config/config.service';




@Component({
    selector: 'app-material-overview',
    templateUrl: './material-overview.component.html',
    styleUrls: ['./material-overview.component.css']
})
export class MaterialOverviewComponent implements OnInit, AfterViewInit {

    // Display type
    DisplayType = DisplayType;

    // Form
    formFilter: FormGroup;

    // Search
    searchForm: FormGroup;
    search() {
        this.doFilter();
    }

    // Paging
    materialTotalCount: number = 0;
    currentItemsCount: number = 0;
    itemsPerPage: number = 25;
    activePage: number = 1;
    paging(event: any) {
        this.activePage = event.detail.page;
        this.doFilter();
    }
    doPaging() {
        this.activePage = 1;
        this.doFilter();
    }

    // Sorting and paging
    @ViewChild(MatSort) sort: MatSort;


    // Displayed columns
    public displayedColumns: string[] = [
        'actions'
    ];

    // Data source
    public data: Material[];

    // Sidenav
    @ViewChild('sidenav') public sidenav: MatSidenav;
    @ViewChildren("myTooltip") myTooltips: QueryList<MatTooltip>;

    // Filter
    public filter: { [key: string]: string } = {};
    private dictSelectOptions: KeyValueDict;
    private displayTypeSelect: AttributeDefinition[];
    public workflow: Workflow;

    // Constructor
    constructor(
        private formBuilder: FormBuilder,
        public material: MaterialService,
        private formatService: FormatService,
        public materialDefinitionService:
            MaterialDefinitionService,
        public user: UserService,
        public workflowService: WorkflowService,
        private clipboard: Clipboard,
        public translateService: TranslateService,
        private configService: ConfigurationService) {

        // Set filter
        materialDefinitionService.materialDefinitions.forEach(def => {
            this.filter[def.technicalName] = null;
        });

        // Set displayed columns
        materialDefinitionService.sortedMaterialDefinitions.forEach(group =>
            group.forEach(row =>
                row.forEach(def => this.displayedColumns.push(def.technicalName))
            )
        );

        //console.warn("Constructor material.length: " + this.material.length);
        //console.warn("Constructor materialTotalCount:" + this.materialTotalCount);
        this.materialTotalCount = this.material?.length;
        this.workflowUsers = [""];
        this.setMaterialTotalCount(this.materialTotalCount);
        this.setCurrentCount(this.materialTotalCount);
    }

    // On init
    ngOnInit() {
        // Create form
        this.searchForm = this.formBuilder.group({
            search: new FormControl('', [])
        });

        // Create form
        this.formFilter = this.formBuilder.group({});
        for (let attributeDefinition of this.materialDefinitionService.materialDefinitions) {
            let initValue = attributeDefinition.displayType != DisplayType.List ? null : [];
            let validators = [];
            for (let val of attributeDefinition.validation) {
                validators.push(val.validator);
            }
            this.formFilter.addControl(attributeDefinition.technicalName, new FormControl(initValue, validators));
        }
        this.dictSelectOptions = new KeyValueDict();
        this.displayTypeSelect = [] as AttributeDefinition[];
        this.displayTypeSelect = this.materialDefinitionService.materialDefinitions
            .filter(x => x.displayType === DisplayType.Select)
            .map(x => x);

        this.displayTypeSelect.map(x => {
            if (!this.dictSelectOptions.has(x.technicalName)) {

                const list = x.displayOptions["select"] as string[];
                this.dictSelectOptions.add(x.technicalName, list);
            }
        });
    }

    // After init
    ngAfterViewInit() {
        // Subscribe sorting
        this.sort.sortChange.subscribe(x => this.doFilter());
        // Get data
        this.doFilter();
    }

    // On filter
    onFilter() {
        this.doFilter();
    }

    // On delete
    onFilterDelete() {
        this.materialDefinitionService.materialDefinitions.forEach(def => {
            this.filter[def.technicalName] = null;
        });
        this.doFilter();
    }

    private async setMaterialTotalCount(itemsCount: any) {
        if (!PorscheTools.isNullOrUndefined(this.material?.length)) {
            itemsCount = this.material?.length;
        }
        if (PorscheTools.isNullOrUndefined(itemsCount)) {
            itemsCount = "0";
        }
        this.materialTotalCount = Number(itemsCount);
    }

    public getMaterialTotalCount() {
        if (PorscheTools.isNullOrUndefined(this.materialTotalCount)
            && !PorscheTools.isNullOrUndefined(this.material?.length)) {
            this.materialTotalCount = this.material?.length;
        }
        return this.materialTotalCount;
    }

    private async setCurrentCount(itemsCount: any) {
        if (PorscheTools.isNullOrUndefined(itemsCount)) {
            itemsCount = "0";
        }
        this.currentItemsCount = Number(itemsCount);
    }

    // Copy to Clipboard
    public async copyToClipboardAsync(material: Material) {
        this.myTooltips.forEach((child) => { if (child.message.includes(material.id)) { child.disabled = false } })
        this.myTooltips.forEach((child) => {
            child.show();
            setTimeout(() => { child.disabled = true; }, 2000);
        })
        
        let text = material.drawingEntryWithIDshort;
        this.clipboard.copy(text);
        return text;
    }

    // Filter the data
    private async doFilter() {
        // Create body
        let body: {
            attributeSearchs: { [key: string]: any },
            attributeFilters: { [key: string]: any },
        } = {
            attributeSearchs: {},
            attributeFilters: {}
        };
        // Create search attributes (localization)
        body.attributeSearchs = {};
        let test = this.materialDefinitionService.materialDefinitions.filter(def => def.isForTable == true);
        this.materialDefinitionService.materialDefinitions.forEach(def => {
            let tmp = [];
            let searchValue = "";
            // let result = this.formatService.fromSearchString(searchValue, def.technicalName, true);
            let result = this.formatService.fromSearchString(this.searchForm.get('search').value, def.technicalName, true);

            result.forEach(r => {
                if (tmp.indexOf(r) == -1) {
                    tmp.push(r);
                }
            });
            if (tmp.length) {
                body.attributeSearchs[def.technicalName] = tmp;
            }
        });

        // Adjust filter
        body.attributeFilters = {}
        for (let key of Object.keys(this.filter)) {
            if (this.filter[key]) {
                body.attributeFilters[key] = this.formatService.fromSearchString(this.filter[key], key);
            };
        }

        const skipVal = this.itemsPerPage * (this.activePage - 1);
        const limitVal = skipVal + this.itemsPerPage;
        // Loop
        this.data = await this.material.Get(
            {
                sortAttribute: (this.sort ? this.sort.active : undefined),
                sortAsc: (this.sort != undefined && this.sort.direction == 'asc'),
                skip: -1,
                limit: -1,
                body: body,
            }
        );
        // await this.mapDataToFilterExportAsync();
        // this.dataService.changeMessage(this.mappedData);
        const count = this.data.length;
        this.data = this.data.slice(skipVal, limitVal);
        this.setCurrentCount(count);
    }

    onSelectChanged(attributeDefinition: AttributeDefinition, target: any) {
        this.clearChildValues(attributeDefinition);
    }

    clearChildValues(attributeDefinition: AttributeDefinition) {
        if (PorscheTools.isNullOrUndefined(attributeDefinition)) {
            return;
        }

        const techName = attributeDefinition.technicalName;
        const list = this.materialDefinitionService.materialDefinitions.filter(x => !PorscheTools.stringIsNullOrEmpty(x.parentDefinitionName));
        const childElements = list.filter(x => x.parentDefinitionName === techName);

        if (childElements.length > 0) {
            childElements.map(x => {
                const e = this.formFilter.get(x.technicalName);
                e.setValue(null);

                this.clearChildValues(x);

                e.updateValueAndValidity();
            });
        }
    }

    getSelectOptions(attributeDefinition: AttributeDefinition) {

        const searchval = this.searchForm.get('search').value;
        let list = [] as string[];
        //return list;

        if (PorscheTools.isNullOrUndefined(attributeDefinition)) {
            return list;
        }

        if (PorscheTools.isNullOrUndefined(this.dictSelectOptions)) {
            return list;
        }

        const techName = attributeDefinition.technicalName;
        const e = this.formFilter.get(techName);

        if (PorscheTools.isNullOrUndefined(e)) {
            return list;
        }


        if (this.dictSelectOptions.has(techName)) {

            let options = this.dictSelectOptions.get(techName);
            if (!PorscheTools.isNullOrUndefined(options)) {


                const optionList = options as string[];

                optionList.map(x => {

                    if (!PorscheTools.stringIsNullOrEmpty(attributeDefinition.parentDefinitionName)) {
                        const eParent = this.formFilter.get(attributeDefinition.parentDefinitionName);
                        if (!PorscheTools.isNullOrUndefined(eParent)) {

                            const parentVal = PorscheTools.StringVal(eParent.value);
                            if (!PorscheTools.stringIsNullOrEmpty(parentVal)) {
                                if (x.toLocaleUpperCase().trim().startsWith(parentVal.toLocaleUpperCase().trim() + "_")) {
                                    list.push(x);
                                }
                            }
                        }
                    }
                    else {
                        list.push(x);
                    }
                });
            }
        }

        e.updateValueAndValidity();
        return list;
    }


    output: string;
    workflowUsers: string[];
    async GetWorkflowByMaterial(material: Material) {

        //workflow = Workflow
        //const workflow = new Workflow();
        //const list = [] as string[];
        //workflow.steps.map(x=>{
        //  const txt = ""
        //  list.push(txt);
        // })
        this.workflowUsers = await this.workflowService.GetWorkflowByMaterialId(material.id);
        // const userArray = await this.workflowService.GetWorkflowByMaterialId(material.id);
        // const userArray = ["1", "2"];
        // return userArray;

    }

    GetHeader(userVal: string) {
        const splitVal = userVal.split('#');
        if (splitVal.length >= 2) {
            return splitVal[0].trim();
        }
        else {
            return userVal.trim();
        }
    }

    GetValue(userVal: string) {
        const splitVal = userVal.split('#');
        if (splitVal.length >= 2) {
            return splitVal[1].trim()
        }
        else {
            return userVal.trim();
        }
    }

    openURL(){
        let url = this.configService.ratingUrl;
        if(this.translateService.currentLang === "en")
        {
            url = url + "&lang=en";
        }
        window.open(url)
    }

}
